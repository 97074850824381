export const addResults = (dataResult) => {
    return {
        type: 'ADD_RESULT',
        payload: dataResult
    }
}

export const resetResults = () => {
    return {
        type: 'RESET_RESULT'
    }
}

export const setTermSearch = (termSearch) => {
    return {
        type: 'SET_TERM_SEARCH',
        term: termSearch
    }
}

export const setTermSelectedCategory = (categoriesSelected) => {
    return {
        type: 'SET_TERM_SEARCH_CATEGORIES',
        selectedCategories: categoriesSelected
    }
}

export const addSelectedCompany = (company) => {
    return {
        type: 'ADD_SELECTED_COMPANY',
        payload: company
    }
}

export const addSelectedProduct = (product) => {
    return {
        type: 'ADD_SELECTED_PRODUCT',
        payload: product
    }
}

export const addAllCategories = (categories) => {
    return {
        type: 'SET_ALL_CATEGORIES',
        payload: categories
    }
}

export const addAllCompanies = (companies) => {
    return {
        type: 'SET_ALL_COMPANIES',
        payload: companies
    }
}

export const selectCategory = (idCategory) => {
    return {
        type: 'SELECT_CATEGORY',
        id: idCategory
    }
}

export const addFavoriteProduct = (fav) => {
    return {
        type: 'ADD_FAVORITE_PRODUCT',
        payload: fav
    }
}

export const addFavoriteCompany = (fav) => {
    return {
        type: 'ADD_FAVORITE_COMPANY',
        payload: fav
    }
}

export const removeFavoriteProduct = (id) => {
    return {
        type: 'REMOVE_FAVORITE_PRODUCT',
        payload: id
    }
}

export const removeFavoriteCompany = (id) => {
    return {
        type: 'REMOVE_FAVORITE_COMPANY',
        payload: id
    }
}

export const removeCategory = (id) => {
    return {
        type: 'REMOVE_CATEGORY',
        payload: id
    }
}

export const resetTeamSearch = (id) => {
    return {
        type: 'RESET_TERM_SEARCH',
        payload: id
    }
}