import React from "react";
import style from "./cardPartners.module.scss"
import DobleDataContainer from "../../DobleDataContainer/dobleDataContainer";
import {useTranslation} from "react-i18next";

function CardPartners({dataList}) {
    const {i18n} = useTranslation('global');
    return (
        dataList.map((partner, i) => (
            <div key={i} className={style.partnerContainer}>
                <div className={style.partnerTitle}>
                    <div className={style.title}>{partner.socio}</div>
                    <div>{partner.tipo} Partner</div>
                </div>

                <DobleDataContainer key={i} location={partner.ubicacion} partnerWeb={partner.web}/>
                <div
                    className={style.partnerDescription}>{i18n.language === 'en' ? partner.detalle_EN : partner.detalle_ES}</div>
            </div>
        ))
    );
}

export default CardPartners;
