const initialState = {};

function companyReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_SELECTED_COMPANY':
            return action.payload
        case 'RESET_COMPANY':
            return initialState
        default:
            return state
    }
}

export default companyReducer
