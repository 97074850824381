import React from "react";
import styles from "./resultLabel.scss"
import {useTranslation} from "react-i18next";

function ResultLabel({length}) {
    const {t} = useTranslation('global');

    return (
        <div className={`${styles.container}`}>
            <small>({length} {length > 1 ? t("resultLabel.text-results") : t("resultLabel.text-result")})</small>
        </div>
    );
}

export default ResultLabel;
