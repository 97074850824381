import React from "react";
import styles from "./dobleDataContainer.module.scss";
import {IoBusinessOutline, IoCashOutline, IoCubeOutline} from "react-icons/io5";
import {FaMapMarkerAlt} from "react-icons/fa";
import {FiFlag} from "react-icons/fi";
import {MdPeopleOutline} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {BiWorld} from "react-icons/bi";

function DobleDataContainer({
                                company,
                                location,
                                foundation,
                                range,
                                clientName,
                                projectName,
                                nameInvestor,
                                amount,
                                partnerWeb
                            }) {
    const {t} = useTranslation('global');

    return (
        <div className={styles.directionContainer}>
            {company
                ? (<div className={styles.block}>
                    <IoBusinessOutline className={styles.icon}/>
                    {company}
                </div>) : null}
            {location
                ? (<div className={styles.block}>
                    <FaMapMarkerAlt className={styles.icon}/>
                    {location}
                </div>) : null}
            {foundation
                ? (<div className={styles.block}>
                    <FiFlag className={styles.icon}/>
                    {`${t("company.text-foundation")} ${foundation}`}
                </div>) : null}
            {range
                ? (<div className={styles.block}>
                    <MdPeopleOutline className={styles.icon}/>
                    {`${range} ${t("company.text-employee")}`}
                </div>) : null}
            {clientName
                ? (<div className={styles.block}>
                    <IoCubeOutline className={styles.icon}/>
                    {clientName}
                </div>) : null}
            {projectName
                ? (<div className={styles.block}>
                    {projectName}
                </div>) : null}
            {nameInvestor
                ? (<div className={styles.block}>
                    <IoCashOutline className={styles.icon}/>
                    {nameInvestor}
                </div>) : null}
            {amount
                ? (<div className={styles.block}>
                    US$ {amount}
                </div>) : null}
            {partnerWeb
                ? (<div className={styles.block}>
                    <BiWorld className={styles.icon}/>
                    {partnerWeb}
                </div>) : null}
        </div>
    );
}

export default DobleDataContainer;
