import axios from "axios";

const baseUrl = process.env.REACT_APP_URL_API
const apiKey = process.env.REACT_APP_API_KEY

export const apiUrlBase = axios.create({
  baseURL: baseUrl,
  headers: {
    'x-api-key': apiKey
  },
});
