import React, {useCallback} from "react";
import styles from "./Tab.module.scss";

function Tab({title, index, selected, setSelectedTab}) {
    const onClick = useCallback(() => {
        setSelectedTab(index)
    }, [setSelectedTab, index])

    return (
        <div
            onClick={onClick}
            className={`${styles.button} ${selected === index ? styles.selected : null}`}>
            {title}
        </div>
    );
}

export default Tab;
