import React from "react";
import styles from "./button.module.scss";

function Button({
  text,
  icon = null,
  onClick,
  isSecondary = false,
  fullWidth = true,
  className = "",
  margin = true,
  tooltip = null,
}) {
  const Icon = icon;

  return (
    <div
      className={`
            ${className} 
            ${styles.button} 
            ${isSecondary ? styles.secondaryButton : styles.primaryButton} 
            ${fullWidth ? styles.fullWidth : ""}
            ${margin ? "" : styles.noMargin}
            ${tooltip ? styles.tooltip : ""}
            `}
      onClick={onClick}
      data-tipo={text}
    >
      {text ? <span style={{ paddingRight: "0.3em" }}>{text}</span> : null}
      {icon ? <Icon className={styles.icon} /> : null}{" "}
      {tooltip ? <div className={styles.tooltiptext}>{tooltip}</div> : null}
    </div>
  );
}

export default Button;
