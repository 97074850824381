import React, {useEffect, useState} from "react";
import styles from './listCompanies.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getAllCompanies} from "../../services/search.service";
import {addAllCompanies} from "../../redux/actions";
import SectionGrid from "../../components/SectionGrid/sectionGrid";
import CardCompanyExtra from "../Cards/CardCompanyExtra/cardCompanyExtra";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function ListCompanies() {
    const dispatch = useDispatch();
    const {t} = useTranslation('global');
    const [isLoading, setIsLoading] = useState(true);
    const [busqueda, setBusqueda]= useState("");
    const [companiesFiltered, setCompaniesFiltered] = useState([]);

    let companies = useSelector(state => {
        return state.companiesReducer
    });

    const handleChange = (e )=>{
        setBusqueda(e.target.value);
        filtrar(e.target.value);
    }

    const filtrar = (terminoBusqueda)=>{
        let resultadosBusqueda = companies.filter((elemento) => {
          if(elemento.nombre.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
          ){
            return elemento;
          }
        });
        setCompaniesFiltered(resultadosBusqueda);
    }

    useEffect(() => {
        setCompaniesFiltered(companies);
        if (Object.keys(companies).length === 0) {
            getAllCompanies().then(res => { 
                setIsLoading(false)
                dispatch(addAllCompanies(res))

            })
        } else {
            setIsLoading(false);
        }
    }, [companies, dispatch]);
    return (
        <div>
            <div className={styles.containerInput}>
                <input
                    type="search"
                    className={styles.class}
                    value={busqueda}
                    placeholder={t("searchBar.search-companies")}
                    onChange={handleChange}
                />
                <button className={styles.btnSuccess}>
                    <FontAwesomeIcon icon={faSearch}/>
                </button>

            </div>            
            <div className={styles.listContainer}>
                <SectionGrid title={t("company.title-company")}>
                {isLoading ? <LoadingSpinner /> : 
                    companiesFiltered.map((company, index) => (
                        <CardCompanyExtra key={index} company={company} />
                    ))
                }
                </SectionGrid>
            </div>
        </div>
    );
}

export default ListCompanies;
