async function clickButton (action, label) {
  window.gtag('event', 'button_click', {
    event_category: action,
    event_label: label
  });
}

async function searchBar (action, label) {
  window.gtag('event', 'searchBar', {
    event_category: action,
    event_label: label
  });
}

async function pageView () {
  window.gtag('event', 'page_view', {
    page_path: window.location.pathname,
    page_title: document.title,
  });
}

async function scroll (scrollPosition) {
  window.gtag('event', 'scroll', {
    event_category: 'Scroll',
    event_label: `Scroll Position: ${scrollPosition}px`,
});
}


export {
  clickButton,
  pageView,
  scroll,
  searchBar
};