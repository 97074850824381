import React, {useState} from "react";
import styles from "./favorites.module.scss"
import Tab from "../../components/Tabs/tab/Tab";
import Tabs from "../../components/Tabs/Tabs";
import {IoBusinessOutline} from "react-icons/io5";
import {FiFileText} from "react-icons/fi";
import {BiBox} from "react-icons/bi";
import {useSelector} from "react-redux";
import CardProduct from "../../components/Cards/CardProduct/cardProduct";
import CardCompany from "../../components/Cards/CardCompany/cardCompany";
import {useTranslation} from "react-i18next";
import StickyBottomContainer from "../../components/StickyBottonContainer/stickyBottomContainer";
import Button from "../../components/Button/button";
import FileSaver from "file-saver"
import {downloadOnePager} from "../../services/file.service";
import * as JSZip from "jszip";
import PopUp from "../../components/PopUp/popUp";

function Favorites() {
    const [progress, setProgress] = useState(-1);
    const [showPopUp, setShowPopUp] = useState(false);

    const {productsFavs, companiesFavs} = useSelector(state => {
        return {
            productsFavs: state.favoriteReducer.productos,
            companiesFavs: state.favoriteReducer.empresas,
        }
    });

    const {t} = useTranslation('global');

    const onDownloadProgress = (progressEvent) => {
        const actualProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        setProgress(actualProgress)
    }

    const handleClosePopUp = () => {
        setProgress(-1)
        setShowPopUp(prev => !prev)
    }
    const handleDownloadAllOnePagers = (lang) => {
        setProgress(0);
        let zip = new JSZip();
        let zipFilename = 'favorites-onePagers';
        let count = 0;

        productsFavs.forEach(product => {
            let template = product.onepager ? product.onepager.nombre : 'default'

            const filename = `onePager-${product.nombre}.pdf`;
            downloadOnePager(product.id, template, lang, {
                responseType: 'blob',
                onDownloadProgress
            }).then(
                res => {
                    zip.file(filename, res.data, {binary: true});
                    count++;
                    if (count === productsFavs.length) {
                        zip.generateAsync({type: "blob"}).then(
                            content => {
                                FileSaver.saveAs(content, zipFilename);
                            }
                        );
                    }
                }
            ).catch(() => {
                setProgress(-2);
            })
        })
    }
    return (
        <div className={styles.favoritesContainer}>
            <Tabs>
                <Tab title={`${t('favorites.title-products')} (${productsFavs.length})`}>
                    {productsFavs.length > 0
                        ? (
                            <>
                                <div className={styles.flexGrid}>
                                    {productsFavs.map((product, index) => (
                                        <CardProduct key={index} product={product}/>
                                    ))}
                                </div>

                                <div className={styles.buttonContainer}>
                                    <Button
                                        onClick={handleClosePopUp}
                                        text={t("favorites.button-download-onePager")}
                                        icon={FiFileText}
                                        className={styles.buttonDesktop}
                                    />
                                </div>

                                {showPopUp ?
                                    (<PopUp
                                            text={t("popup.text-one-pager")}
                                            onClickButton={handleDownloadAllOnePagers}
                                            progress={progress}
                                            onClose={handleClosePopUp}
                                        />
                                    ) : null}


                                <StickyBottomContainer>
                                    <Button
                                        onClick={handleClosePopUp}
                                        text={t("favorites.button-download-onePager")}
                                        icon={FiFileText}
                                    />
                                </StickyBottomContainer>
                            </>
                        )
                        : (
                            <div className={styles.emptySection}>
                                <BiBox className={styles.icon}/>
                                <p>
                                    {t('favorites.empty-products')}
                                </p>
                            </div>
                        )}

                </Tab>
                <Tab title={`${t('favorites.title-companies')} (${companiesFavs.length})`}>
                    {companiesFavs.length > 0
                        ? (
                            <>
                                <div className={styles.flexGrid}>
                                    {companiesFavs.map((company, index) => (
                                        <CardCompany key={index} company={company}/>
                                    ))}
                                </div>
                            </>
                        )
                        : (<div className={styles.emptySection}>
                            <IoBusinessOutline className={styles.icon}/>
                            <p>
                                {t('favorites.empty-companies')}
                            </p>
                        </div>)
                    }
                </Tab>
            </Tabs>

        </div>
    );
}

export default Favorites;
