import React from "react";
import styles from "./sectionTitle.module.scss"

function SectionTitle({text}) {
    return (
        <div className={`${styles.titleContainer}`}>
            <h4>{text}</h4>
        </div>
    );
}

export default SectionTitle;
