import React from "react";
import styles from "./title.module.scss"

function Title({children}) {
    return (
        <div className={styles.titleContainer}>
            <div className={styles.titleContent}>
                {children}
            </div>
        </div>
    );
}

export default Title;
