import React, {useEffect, useState} from "react";
import styles from "./cardCompanyExtra.module.scss";
import {FaMapMarkerAlt} from "react-icons/fa";
import {getDataCompanyById} from "../../../services/search.service";
import {addFavoriteCompany, addSelectedCompany, removeFavoriteCompany,} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AddToFavoriteButton from "../../AddToFavoriteButton/addToFavoriteButton";
//import useCollapse from 'react-collapsed';
import Collapsible from 'react-collapsible';
import DobleDataContainer from "../../DobleDataContainer/dobleDataContainer";
import Button from "../../Button/button";
import {
    MdKeyboardArrowUp,
    MdKeyboardArrowDown
  } from "react-icons/md";
import { BiWorld, BiMap, } from "react-icons/bi";
import { HiOutlineMail, } from "react-icons/hi";
import {IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin,} from "react-icons/io";
import {RiPhoneLine} from "react-icons/ri";

function CardCompanyExtra({company}) {
    const dispatch = useDispatch();
    const {t} = useTranslation('global');
    const history = useHistory();
    const {i18n} = useTranslation('global');
    const [isFavorite, setFavorite] = useState(false);
    //const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    const [copied, setCopied] = useState(false);
    const [showMoreOffices, setShowMoreOffices] = useState(false);


    const {favoriteCompanies} = useSelector(state => {
        return {
            favoriteCompanies: state.favoriteReducer.empresas
        }
    });
    const handleClickViewCompany = async (company) => {
        let companyName = company.nombre;
        let companyNameUp = '';
        companyName = companyName.split(' ');
        companyName.forEach(word => {
            if (!!word) {
                if (!!companyNameUp) {
                    companyNameUp += '-' + word;
                } else {
                    companyNameUp += word;
                }
                
            }
        })
        await getDataCompanyById(companyNameUp).then(res => {
            dispatch(addSelectedCompany(res))
        });

        history.push("/company/" + companyNameUp)
    }

    const handleMoreOffices = () => {
        setShowMoreOffices((prev) => !prev);
      };

    const handleFavorite = () => {
        dispatch(addFavoriteCompany(company))
        setFavorite(true)
    }

    const handleRemoveFavorite = () => {
        dispatch(removeFavoriteCompany(company.id))
        setFavorite(false)
    }

    const handleButtonContact = (event, url) => {
        switch (event.currentTarget.dataset.tipo) {
          case "Email":
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = url;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            setCopied(true);
            setTimeout(() => setCopied(false), 5000);
            break;
          default:
            const newWindow = window.open(url, "_blank", "noopener,noreferrer");
            if (newWindow) newWindow.opener = null;
            break;
        }
      };

    useEffect(() => {

        let indexProduct = favoriteCompanies.findIndex(item => item.id === company.id);
        if (indexProduct > -1) {
            setFavorite(true)
        }

    }, [company, dispatch, favoriteCompanies]);

    return (
        <Collapsible 
            trigger={company.nombre}
            className={styles.collapsible}
            triggerClassName={styles.stickyButton}
            triggerOpenedClassName={styles.stickyButton}
        >
            <div>
                <div className={styles.cardContainer}>
                
                <div className="content">
                <AddToFavoriteButton
                    isFavorite={isFavorite}
                    handleFavorite={handleFavorite}
                    handleRemoveFavorite={handleRemoveFavorite}
                />
                <div onClick={() => handleClickViewCompany(company)}>
                    <div className={styles.titleCardContainer}>
                        <div className={styles.logoContainer}>
                            {company.logoUrl
                                ? (<img src={company.logoUrl} alt={'logo-' + company.nombre}/>)
                                : null
                            }
                            {company.logo
                                ? (<img src={company.logo.url} alt={'logo-' + company.nombre}/>)
                                : null
                            }
                        </div>
                        <div>
                            <div className={styles.cardName}>{company.nombre}</div>
                            <div className={styles.cardSlogan}>{company.slogan_ES}</div>
                        </div>
                    </div>
                    <DobleDataContainer
                        foundation={company.fundacion}
                        range={company.rangoEmpleados}
                    />
                    <div className={styles.dataContainer}>
                        <div className={styles.descriptionContainer}>
                            <div
                                className={`${styles.cardDescription}`}>
                                    {i18n.language === 'en' ? company.descripcion_EN : company.descripcion_ES}
                                </div>
                            </div>
                        </div>
                        <div>
                        {company.oficinas.map((oficina, index) => {
                        return (
                            <div key={index}>
                            {oficina.principal ? (
                                <div className={styles.directionContainer}>
                                <div className={styles.dataTitle}>
                                    {t("company.title-principal-office")}
                                </div>
                                <div className={styles.streetContainer}>
                                    <BiMap className={styles.icon} />
                                    <div>
                                    <div>
                                        {oficina.calle + ","}
                                    </div>
                                    <div>
                                        {oficina.ciudad || "" +
                                        ", " +
                                        oficina.provincia || "" +
                                        ", " +
                                        oficina.pais || ""}
                                    </div>
                                     </div>
                                </div>
                                <div className={styles.streetContainer}>
                                    <RiPhoneLine className={styles.icon} />
                                    <div>{oficina.telefono}</div>
                                </div>
                                {company.oficinas.length > 1 ? (
                                    <>
                                    <div
                                        className={`${styles.moreOffices} ${styles.dataTitle}`}
                                        onClick={handleMoreOffices}
                                    >
                                        <div>
                                        {t("company.show-more")}
                                        {showMoreOffices ? (
                                            <MdKeyboardArrowUp />
                                        ) : (
                                            <MdKeyboardArrowDown />
                                        )}
                                        </div>
                                    </div>
                                    {showMoreOffices ? (
                                        <div className={styles.dataTitle}>
                                        {t("company.title-secondary-office")}
                                        </div>
                                    ) : null}
                                    </>
                                ) : null}
                                </div>
                            ) : showMoreOffices ? (
                                <div className={styles.directionContainer}>
                                <div className={styles.streetContainer}>
                                    <BiMap className={styles.icon} />
                                    <div>
                                    <div>
                                        {oficina.calle + " " + oficina.altura + ","}
                                    </div>
                                    <div>
                                        {oficina.ciudad +
                                        ", " +
                                        oficina.provincia +
                                        ", " +
                                        oficina.pais}
                                    </div>
                                    </div>
                                </div>
                                <div className={styles.streetContainer}>
                                    <RiPhoneLine className={styles.icon} />
                                    <div>{oficina.telefono}</div>
                                </div>
                                </div>
                            ) : null}
                            </div>
                        );
                        })}
                        </div>
                    </div>
                    <div>
                    <div className={styles.dataTitle}>
                        {t("company.section-contact")}
                        <div className={styles.contactContainer}>
                            <div className={styles.contactRow}>
                                <Button
                                    isSecondary={true}
                                    text={"Email"}
                                    icon={HiOutlineMail}
                                    tooltip={
                                    (copied
                                        ? "Email en el portapapeles: "
                                        : "Copiar email: ") + company.contacto.email
                                    }
                                    onClick={(e) =>
                                    handleButtonContact(e, company.contacto.email)
                                    }
                                />
                                <Button
                                    isSecondary={true}
                                    text={"Web"}
                                    icon={BiWorld}
                                    onClick={(e) =>
                                    handleButtonContact(e, company.contacto.web)
                                    }
                                />
                            </div>
                            <div className={styles.contactRow}>
                                {company.contacto.facebook ? (
                                    <Button
                                    isSecondary={true}
                                    icon={IoLogoFacebook}
                                    onClick={(e) =>
                                        handleButtonContact(e, company.contacto.facebook)
                                    }
                                    />
                                ) : null}
                                {company.contacto.instagram ? (
                                    <Button
                                    isSecondary={true}
                                    icon={IoLogoInstagram}
                                    onClick={(e) =>
                                        handleButtonContact(e, company.contacto.instagram)
                                    }
                                    />
                                ) : null}
                                {company.contacto.linkedin ? (
                                    <Button
                                    isSecondary={true}
                                    icon={IoLogoLinkedin}
                                    onClick={(e) =>
                                        handleButtonContact(e, company.contacto.linkedin)
                                    }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
        </Collapsible>
    );
}

export default CardCompanyExtra;
