import React from "react";
import styles from './stickyBottonContainer.module.scss'

function StickyBottomContainer({isHidden, children}) {
    return (
        <div className={`${styles.stickyButtonContainer} ${isHidden ? styles.hide : null}`}>
            {children}
        </div>
    );
}

export default StickyBottomContainer;
