import React from "react";
import style from "./alert.module.scss"

function Alert({text}) {
    return (
        <div className={style.alertContainer}>
            {text}
        </div>
    )
        ;
}

export default Alert;
