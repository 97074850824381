const initialState = {
    productos: [],
    empresas: [],
    showEmpty: false
};


function searchReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_RESULT':
            if (action.payload) {
                if (action.payload.empresas.length === 0 && action.payload.productos.length === 0) {
                    return {
                        ...action.payload,
                        showEmpty: true
                    }
                }

                return {
                    ...action.payload,
                    showEmpty: false
                };
            }
            return initialState
        case 'RESET_RESULT':
            return initialState
        default:
            return state
    }
}


export default searchReducer

