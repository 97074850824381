import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDataCompanyById,
  getDataProductById,
} from "../../services/search.service";
import {
  addFavoriteProduct,
  addSelectedProduct,
  removeFavoriteProduct,
} from "../../redux/actions";
import styles from "./product.module.scss";
import EmptyResult from "../../components/EmptyResult/emptyResult";
import Video from "../../components/Video/video";
import DobleDataContainer from "../../components/DobleDataContainer/dobleDataContainer";
import { useTranslation } from "react-i18next";
import StickyBottomContainer from "../../components/StickyBottonContainer/stickyBottomContainer";
import CardCompany from "../../components/Cards/CardCompany/cardCompany";
import Section from "../../components/Section/section";
import Chips from "../../components/Chips/chips";
import Title from "../../components/Title/title";
import Controls from "../../components/Controls/controls";
import PopUp from "../../components/PopUp/popUp";
import Button from "../../components/Button/button";
import { downloadOnePager } from "../../services/file.service";
import FileSaver from "file-saver";
import ImageGallery from "../../components/ImageGalery/imageGallery";
import {
  IoMdHeart,
  IoMdHeartEmpty,
} from "react-icons/io";
import {FiFileText} from "react-icons/fi";
import {BiWorld} from "react-icons/bi";

function Product() {
  const { t, i18n } = useTranslation("global");
  const dispatch = useDispatch();
  let { id } = useParams();

  const [company, setCompany] = useState({});
  const [isFavorite, setFavorite] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [progress, setProgress] = useState(-1);

  const { product, favoriteProducts } = useSelector((state) => {
    return {
      product: state.productReducer,
      favoriteProducts: state.favoriteReducer.productos,
    };
  });

  const handleButtonOnePager = () => {
    setShowPopUp(true);
    setProgress(-1);
  };

  const handleButtonContact = (event, url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleFavorite = () => {
    dispatch(addFavoriteProduct(product));
    setFavorite(true);
  };

  const handleRemoveFavorite = () => {
    dispatch(removeFavoriteProduct(product.id));
    setFavorite(false);
  };

  const handleClosePopUp = () => {
    setShowPopUp((prev) => !prev);
  };

  const onDownloadProgress = (progressEvent) => {
    const actualProgress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setProgress(actualProgress);
  };

  const handleOnClickDownload = (lang) => {
    setProgress(0);
    let template = product.onepager ? product.onepager.nombre : "default";
    downloadOnePager(product.id, template, lang, {
      responseType: "blob",
      onDownloadProgress,
    })
      .then((res) => {
        FileSaver.saveAs(res.data, `onePager-${product.nombre}.pdf`);
      })
      .catch(() => {
        setProgress(-2);
      });
  };

  useEffect(() => {
    if (Object.keys(product).length === 0) {
      getDataProductById(id).then((res) => {
        dispatch(addSelectedProduct(res));
      });
    }

    if (Object.keys(product).length > 0) {
      getDataCompanyById(product.empresaId).then((res) => {
        setCompany(res);
      });
    }

    let indexProduct = favoriteProducts.findIndex(
      (item) => item.id === product.id
    );
    if (indexProduct > -1) {
      setFavorite(true);
    }
  }, [product, dispatch, id, favoriteProducts]);

  return (
    <div>
      {Object.keys(product).length > 0 ? (
        <div className={styles.productContainer}>
          <Title>
            <div>
              <div className={styles.title}>{product.nombre}</div>
              <div className={styles.subTitle}>
                {i18n.language === "en"
                  ? product.funcionalidad_EN
                  : product.funcionalidad_ES}
              </div>
            </div>
            <Controls
              isFavorite={isFavorite}
              handleFavorite={handleFavorite}
              handleRemoveFavorite={handleRemoveFavorite}
              handleButtonWeb={(e) =>
                handleButtonContact(e, product.web ? product.web : (!!company ? company.contacto ? company.contacto.web : product.web : ''))
              }
              handleButtonOnePager={handleButtonOnePager}
            />
          </Title>
          {showPopUp ? (
            <PopUp
              text={t("popup.text-single-one-pager")}
              onClose={handleClosePopUp}
              onClickButton={handleOnClickDownload}
              progress={progress}
            />
          ) : null}
          <Section>
            <Chips dataArray={product.categorias} isPrimary={true} />
            <div className={styles.productDescription}>
              {i18n.language === "en"
                ? product.descripcion_EN
                    .split("\n")
                    .map((paragraph, k) => <div key={k}>{paragraph} </div>)
                : product.descripcion_ES
                    .split("\n")
                    .map((paragraph, k) => <div key={k}>{paragraph} </div>)}
            </div>
            <DobleDataContainer
              company={company.nombre}
              location={company.oficinas ? company.oficinas[0].ciudad : ""}
            />
          </Section>

          {product.video ? (
            <Section title={t("product.section-video")}>
              <Video urlVideo={product.video} />
            </Section>
          ) : null}

          {product.funcionalidad_ES || product.funcionalidad_EN ? (
            <Section title={t("product.section-features")}>
              <p>{product.funcionalidad_ES}</p>
            </Section>
          ) : null}

          {product.mercado_ES || product.mercado_EN ? (
            <Section title={t("product.section-market")}>
              <p>{product.mercado_ES}</p>
            </Section>
          ) : null}

          {product.fotos && product.fotos?.length > 0 ? (
            <Section title={t("product.section-pictures")}>
              <ImageGallery arrayPhotos={product.fotos} />
            </Section>
          ) : null}

          {Object.keys(company)?.length > 0 && (
            <Section grey={true} title={t("product.section-product-owner")}>
              <CardCompany company={company} />
            </Section>
          )}

          <StickyBottomContainer>
            {isFavorite ? (
              <Button
                fullWidth={false}
                icon={IoMdHeart}
                onClick={handleRemoveFavorite}
                isSecondary={true}
              />
            ) : (
              <Button
                fullWidth={false}
                icon={IoMdHeartEmpty}
                onClick={handleFavorite}
                isSecondary={true}
              />
            )}
            <Button
              onClick={(e) => handleButtonContact(e,  product.web ? product.web : (!!company ? company.contacto ? company.contacto.web : product.web : ''))}
              text={t("controls.button-goto-web-mobile")}
              icon={BiWorld}
              isSecondary={true}
            />
            <Button
              onClick={handleButtonOnePager}
              text={t("controls.button-one-pager")}
              icon={FiFileText}
            />
          </StickyBottomContainer>
        </div>
      ) : (
        <EmptyResult />
      )}
    </div>
  );
}

export default Product;
