import React from "react";
import styles from "../chips.module.scss";
import {IoFilter} from "react-icons/io5";
import {IoMdClose} from "react-icons/io";

function Chip({data, isSelected, onClick, isPrimary, onClose, isFilter = false}) {
    return (
        <div
            className={`${styles.chip} 
                        ${isSelected ? styles.selected : ''} 
                        ${isPrimary ? styles.selected : ''}`}
            onClick={onClose ? onClose : onClick}
        >{data}
            {onClose ? (<IoMdClose className={styles.icon}/>) : null}
            {isFilter ? (<IoFilter className={styles.icon}/>) : null}
        </div>
    );
}

export default Chip;
