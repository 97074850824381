import React from 'react'
import styles from './imageGalery.module.scss'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function ImageGallery({arrayPhotos}) {


    return (
        <div className={styles.container}>
            {arrayPhotos.map((photo, i) => (
                <div key={i} className={styles.imageContainer}>
                    <Zoom>
                        <img className={styles.image} src={photo.url} alt={'product-image-' + photo.nombre}/>
                    </Zoom>
                </div>
            ))}
        </div>
    );
}

export default ImageGallery;
