import React from "react";
import styles from "./filteredCategories.module.scss";
import {resetResults} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Chips from "../Chips/chips";
import Chip from "../Chips/Chip/chip";


function FilteredCategories() {
    const dispatch = useDispatch();
    const {t} = useTranslation('global');


    const categories = useSelector(state => {
        return state.categoriesReducer
    });

    const handleOpenFilters = () => {
        dispatch(resetResults())
    }
    return (
        <div className={styles.categoriesFlatSection}>
            <div className={styles.filterContent}>
                <div className={styles.title}>{t("list-categories.selected-title")}</div>
                <div>
                    {
                        <Chips
                            dataArray={categories}
                            isCloseable={true}
                            showSelected={true}
                            isSelectable={true}
                        />
                    }

                    <Chip isFilter={true} onClick={handleOpenFilters} data={t("list-categories.default-category")}/>
                </div>
            </div>
        </div>
    );
}

export default FilteredCategories;
