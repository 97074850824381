import React, {useState} from "react";
import styles from "./Tabs.module.scss";
import Tab from "./tab/Tab";

function Tabs({children}) {
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <div>
            <div className={styles.tabsContainer}>
                {
                    children.map((item, index) => (
                        <Tab
                            key={index}
                            title={item.props.title}
                            index={index}
                            selected={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    ))
                }
            </div>

            {children[selectedTab].props.children}
        </div>
    );
}

export default Tabs;
