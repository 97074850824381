import React from "react";
import styles from './cardEmployee.module.scss'
import Button from "../Button/button";
import {IoLogoLinkedin} from "react-icons/io";

const URL_IMG_ERROR = "../../assets/images/employeeNotFound.png"

function CardEmployee({dataList}) {
  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = URL_IMG_ERROR
  }

  const handleButtonContact = (event, url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className={styles.cardEmployeeContainer}>
      {
        dataList.map((employee, i) => (
          <div key={i} className={styles.cardEmployee}>
            <div className={styles.imgContainer}>
              <img src={employee.foto ? employee.foto : URL_IMG_ERROR} onError={onError} alt={`Profile of ${employee.nombre}`}/>
            </div>
            <div className={styles.dataContainer}>

              <div>{employee.rol}</div>
              <div className={styles.name}>{employee.nombre} {employee.apellido}</div>
              <div>{employee.email}</div>
              <div>{employee.telefono}</div>
              <div className={styles.buttonContainerMobile}>
                <Button margin={false} fullWidth={false} onClick={(e) => handleButtonContact(e, employee.linkedin)}
                        icon={IoLogoLinkedin} isSecondary={true}/>
              </div>
            </div>
            <div className={styles.buttonContainerDesktop}>
              <Button fullWidth={false} onClick={(e) => handleButtonContact(e, employee.linkedin)}
                      icon={IoLogoLinkedin} isSecondary={true}/>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default CardEmployee;
