import React, {useEffect, useState} from "react";
import styles from "./cardCompany.module.scss";
import {FaMapMarkerAlt} from "react-icons/fa";
import {getDataCompanyById} from "../../../services/search.service";
import {addFavoriteCompany, addSelectedCompany, removeFavoriteCompany,} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AddToFavoriteButton from "../../AddToFavoriteButton/addToFavoriteButton";

function CardCompany({company}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {i18n} = useTranslation('global');
    const [isFavorite, setFavorite] = useState(false);


    const {favoriteCompanies} = useSelector(state => {
        return {
            favoriteCompanies: state.favoriteReducer.empresas
        }
    });
    const handleClickViewCompany = async (company) => {
        let companyName = company.nombre;
        let companyNameUp = '';
        companyName = companyName.split(' ');
        companyName.forEach(word => {
            if (!!word) {
                if (!!companyNameUp) {
                    companyNameUp += '-' + word;
                } else {
                    companyNameUp += word;
                }
                
            }
        })
        await getDataCompanyById(companyNameUp).then(res => {
            dispatch(addSelectedCompany(res))
        });

        history.push("/company/" + companyNameUp)
    }

    const handleFavorite = () => {
        dispatch(addFavoriteCompany(company))
        setFavorite(true)
    }

    const handleRemoveFavorite = () => {
        dispatch(removeFavoriteCompany(company.id))
        setFavorite(false)
    }

    useEffect(() => {

        let indexProduct = favoriteCompanies.findIndex(item => item.id === company.id);
        if (indexProduct > -1) {
            setFavorite(true)
        }

    }, [company, dispatch, favoriteCompanies]);

    return (
        <div className={styles.cardContainer}>

            <AddToFavoriteButton
                isFavorite={isFavorite}
                handleFavorite={handleFavorite}
                handleRemoveFavorite={handleRemoveFavorite}
            />
            <div onClick={() => handleClickViewCompany(company)}>
                <div className={styles.titleCardContainer}>
                    <div className={styles.logoContainer}>
                        {company.logoUrl
                            ? (<img src={company.logoUrl} alt={'logo-' + company.nombre}/>)
                            : null
                        }
                        {company.logo
                            ? (<img src={company.logo.url} alt={'logo-' + company.nombre}/>)
                            : null
                        }
                    </div>
                    <div>
                        <div className={styles.cardName}>{company.nombre}</div>
                        <div className={styles.cardSlogan}>{company.slogan_ES}</div>
                    </div>
                </div>

                <div
                    className={`${styles.cardDescription} ${styles.truncate}`}>{i18n.language === 'en' ? company.descripcion_EN : company.descripcion_ES}</div>
                <div className={styles.center}>
                    <FaMapMarkerAlt className={styles.icon}/>
                    {company.oficina
                        ? (company.oficina.ciudad + ', ' + company.oficina.pais)
                        : null
                    }

                    {
                        company.oficinas && company.oficinas.map((office, i) => office.principal
                            ? (<span key={i}>{office.provincia}, {office.pais}</span>)
                            : null)
                    }
                </div>
            </div>
        </div>
    );
}

export default CardCompany;
