import React, {useEffect, useState} from "react";
import styles from "./cardProduct.module.scss";
import DobleDataContainer from "../../DobleDataContainer/dobleDataContainer";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getDataProductById} from "../../../services/search.service";
import {addFavoriteProduct, addSelectedProduct, removeFavoriteProduct} from "../../../redux/actions";
import Chips from "../../Chips/chips";
import AddToFavoriteButton from "../../AddToFavoriteButton/addToFavoriteButton";
import {useTranslation} from "react-i18next";

function CardProduct({product}) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isFavorite, setFavorite] = useState(false);
    const {favoriteProducts} = useSelector(state => {
        return {
            favoriteProducts: state.favoriteReducer.productos
        }
    });

    const {i18n} = useTranslation('global');


    const handleClickViewProduct = async () => {
        let productName = product.nombre;
        let productNameUp = '';
        productName = productName.split(' ');
        productName.forEach(word => {
            if (!!word) {
                if (!!productNameUp) {
                    productNameUp += '-' + word;
                } else {
                    productNameUp += word;
                }
                
            }
        })

        await getDataProductById(productNameUp)
            .then(res => {
                dispatch(addSelectedProduct(res))
            }
        );
        history.push("/product/" + productNameUp)
    }

    const handleFavorite = () => {
        dispatch(addFavoriteProduct(product))
        setFavorite(true)
    }

    const handleRemoveFavorite = () => {
        dispatch(removeFavoriteProduct(product.id))
        setFavorite(false)
    }

    const handleCompanyLocation = (company) => {
        if(company.oficina){
            return company.oficina.pais;
        }else if(company.oficinas){
            return company.oficinas[0].pais;
        }else{
            return null
        }
    }

    useEffect(() => {

        let indexProduct = favoriteProducts.findIndex(item => item.id === product.id);
        if (indexProduct > -1) {
            setFavorite(true)
        } else {
            setFavorite(false)
        }

    }, [product, dispatch, favoriteProducts]);

    return (
        <div className={styles.cardContainer}>
            <AddToFavoriteButton
                isFavorite={isFavorite}
                handleFavorite={handleFavorite}
                handleRemoveFavorite={handleRemoveFavorite}
            />
            <div onClick={handleClickViewProduct}>
                <div className={styles.titleCardContainer}>
                    <div className={styles.logoContainer}>
                        {product.fotos !== null
                            ? (product.fotos.url[0] ? <img src={product.fotos.url[0].url} onError={i => i.target.style.display='none'} alt={'logo-' + product.nombre}/> : null)
                            : null
                        }
                    </div>
                    <div>
                        <div className={styles.cardTitle}>{product.nombre}</div>
                        <div className={styles.cardSubTitle}>{product.tipo}</div>
                    </div>
                </div>

                {Array.isArray(product.matchValue) && product.matchValue
                    ? (<Chips dataArray={product.matchValue} isPrimary={true}/>)
                    : null}

                {product.categorias
                    ? (<Chips dataArray={product.categorias} isPrimary={true}/>)
                    : null
                }

                <div className={`${styles.cardDescription} ${styles.truncate}`}>
                    {i18n.language === 'en' ? product.descripcion_EN : product.descripcion_ES}
                </div>
                {
                    product.empresa ?
                        (<DobleDataContainer company={product.empresa.nombre} location={handleCompanyLocation(product.empresa)}/>)
                        : null

                }
            </div>
        </div>
    );
}

export default CardProduct;
