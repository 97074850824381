import React from "react";
import styles from "./emptyResult.module.scss";
import {useTranslation} from "react-i18next";

function EmptyResult() {
    const {t} = useTranslation('global');

    return (
        <div className={styles.emptySearchMessage}>
            <img src='../assets/images/light.png' alt='Foco de ideas'/>
            <p>
                {t("empty-result.empty-help")}
            </p>
        </div>
    );
}

export default EmptyResult;
