import React, {useCallback, useEffect} from "react";
import styles from "./searchBar.module.scss"
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {getServicesByTerm, getFilter} from "../../services/search.service";
import {addResults, resetResults, setTermSearch} from "../../redux/actions";
import { searchBar } from "../../services/analytic.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchBar() {

    const history = useHistory();
    const dispatch = useDispatch();
    let query = useQuery();

    const {t} = useTranslation('global');

    const {userSearch, termSearch} = useSelector(state => {
        return {
            userSearch: state.searchTermReducer.userSearch,
            termSearch: state.searchTermReducer.stringCompleteSearch
        }
    });

    const handleSearchButton = () => {
        searchByTerm();
    }

    const handleOnChangeTerm = (value) => {
        dispatch(setTermSearch(value))
    }

    const handleKeyDown = (e) => {
        if (["Enter"].includes(e.key)) {
            e.preventDefault();
            searchByTerm()
        }
    };

    const searchByTerm = () => {
        if (userSearch.length > 2) {
            /*getServicesByTerm(termSearch).then(
                res => {
                    dispatch(addResults(res));
                    history.push('/search');
                }
            );*/

            getFilter(termSearch).then(
                res => {
                    dispatch(addResults(res));
                    history.push('/search');
                }
            );
            searchBar('Buscador', termSearch)
        } else {
            dispatch(resetResults())
        }

    }

    const getTermSearch = useCallback(() => {
        let termFromQuery = query.get("term");
        if (termFromQuery) {
            dispatch(setTermSearch(termFromQuery))

            /*getServicesByTerm(termFromQuery).then(
                res => {
                    dispatch(addResults(res));
                    history.push('/search');
                }
            );*/

            getFilter(termFromQuery).then(
                res => {
                    dispatch(addResults(res));
                    history.push('/search');
                }
            );
        }
    }, [dispatch, history, query])

    useEffect(() => {
        getTermSearch()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.searchBarContainer}>
            <input type='search' placeholder={t("searchBar.search-label")}
                   onChange={event => handleOnChangeTerm(event.target.value)}
                   onKeyDown={handleKeyDown}
                   value={userSearch}
            />
            <div className={`${styles.buttonSearch} ${userSearch.length > 2 ? styles.available : ''}`}
                 onClick={handleSearchButton}>{t("searchBar.search-bar-label")}
            </div>
        </div>
    );
}

export default SearchBar;
