const initialState = {};

function productReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_SELECTED_PRODUCT':
            return {
                ...action.payload.producto,
                empresaId: action.payload.empresaId,
            }
        case 'RESET_PRODUCT':
            return {
                initialState
            }
        default:
            return state
    }
}

export default productReducer
