import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyResult from "../../components/EmptyResult/emptyResult";
import styles from "./company.module.scss";
import {
  BiMap,
  BiWorld,
} from "react-icons/bi";
import {FiFileText} from "react-icons/fi";
import {HiOutlineMail} from "react-icons/hi";
import {RiPhoneLine} from "react-icons/ri";
import {IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMdHeart,
  IoMdHeartEmpty,} from "react-icons/io";
import {MdKeyboardArrowDown,
  MdKeyboardArrowUp,} from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  addFavoriteCompany,
  addSelectedCompany,
  removeFavoriteCompany,
} from "../../redux/actions";
import { getDataCompanyById } from "../../services/search.service";
import CardEmployee from "../../components/RowPersonal/cardEmployee";
import CardPartners from "../../components/Cards/CardPartners/cardPartners";
import { useTranslation } from "react-i18next";
import ListResultProducts from "../../components/ListResult/listResultProducts";
import Section from "../../components/Section/section";
import Title from "../../components/Title/title";
import Controls from "../../components/Controls/controls";
import DobleDataContainer from "../../components/DobleDataContainer/dobleDataContainer";
import Button from "../../components/Button/button";
import PopUp from "../../components/PopUp/popUp";
import StickyBottomContainer from "../../components/StickyBottonContainer/stickyBottomContainer";
import SectionGrid from "../../components/SectionGrid/sectionGrid";
import Chips from "../../components/Chips/chips";

function Company() {
  const { t, i18n } = useTranslation("global");
  const dispatch = useDispatch();
  let { id } = useParams();
  const [isFavorite, setFavorite] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [copied, setCopied] = useState(false);
  const [showMoreOffices, setShowMoreOffices] = useState(false);

  const { company, favoriteCompanies } = useSelector((state) => {
    return {
      company: state.companyReducer,
      favoriteCompanies: state.favoriteReducer.empresas,
    };
  });
  const handleButtonBrochure = () => {
    setShowPopUp(true);
    setProgress(-1);
  };

  const handleFavorite = () => {
    dispatch(addFavoriteCompany(company));
    setFavorite(true);
  };

  const handleRemoveFavorite = () => {
    dispatch(removeFavoriteCompany(company.id));
    setFavorite(false);
  };

  const handleButtonContact = (event, url) => {
    switch (event.currentTarget.dataset.tipo) {
      case "Email":
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
        break;
      default:
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
        break;
    }
  };

  const handleMoreOffices = () => {
    setShowMoreOffices((prev) => !prev);
  };

  const handleClosePopUp = () => {
    setShowPopUp((prev) => !prev);
  };

  const handleOnClickDownload = (lang) => {
    let urlBrochure = lang === "ES" ? company.brochure_ES : company.brochure_EN;

    if (!!urlBrochure) {
      urlBrochure = urlBrochure.url
    }

    const newWindow = window.open(urlBrochure, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (Object.keys(company).length === 0) {
      getDataCompanyById(id).then((res) => {
        dispatch(addSelectedCompany(res));
      });
    }

    let indexProduct = favoriteCompanies.findIndex(
      (item) => item.id === company.id
    );
    if (indexProduct > -1) {
      setFavorite(true);
    }
  }, [company, dispatch, favoriteCompanies, id]);

  return (
    <div>
      {Object.keys(company).length > 0 ? (
        <div className={styles.companyContainer}>
          <Title>
            <div style={{ display: "flex", alignItems: "center" }}>
              {company.logo ? (
                <div className={styles.logoContainer}>
                  <img src={company.logo.url} alt={"logo-" + company.nombre} />
                </div>
              ) : null}

              <div>
                <div className={styles.title}>{company.nombre}</div>
                <div className={styles.subtitle}>{company.razonSocial}</div>
              </div>
            </div>

            <Controls
              isFavorite={isFavorite}
              handleFavorite={handleFavorite}
              handleRemoveFavorite={handleRemoveFavorite}
              handleButtonWeb={(e) =>
                handleButtonContact(e, company.contacto.web)
              }
              handleButtonBrochure={handleButtonBrochure}
            />
          </Title>
          {showPopUp ? (
            <PopUp
              text={t("popup.text-single-brochure")}
              onClose={handleClosePopUp}
              onClickButton={handleOnClickDownload}
              progress={progress}
            />
          ) : null}
          <Section>
            <div className={styles.dataContainer}>
              <div className={styles.descriptionContainer}>
                <DobleDataContainer
                  foundation={company.fundacion}
                  range={company.rangoEmpleados}
                />
                <div className={`${styles.description}`}>
                  {i18n.language === "en"
                    ? company.descripcion_EN
                        .split("\n")
                        .map((paragraph, k) => <div key={k}>{paragraph} </div>)
                    : company.descripcion_ES
                        .split("\n")
                        .map((paragraph, k) => <div key={k}>{paragraph}</div>)}
                </div>
              </div>
              <div>
                {company.oficinas.map((oficina, index) => {
                  return (
                    <div key={index}>
                      {oficina.principal ? (
                        <div className={styles.directionContainer}>
                          <div className={styles.dataTitle}>
                            {t("company.title-principal-office")}
                          </div>
                          <div className={styles.streetContainer}>
                            <BiMap className={styles.icon} />
                            <div>
                              <div>
                                {oficina.calle + ","}
                              </div>
                              <div>
                                {oficina.ciudad || "" +
                                  ", " +
                                  oficina.provincia || "" +
                                  ", " +
                                  oficina.pais || ""}
                              </div>
                            </div>
                          </div>
                          <div className={styles.streetContainer}>
                            <RiPhoneLine className={styles.icon} />
                            <div>{oficina.telefono}</div>
                          </div>
                          {company.oficinas.length > 1 ? (
                            <>
                              <div
                                className={`${styles.moreOffices} ${styles.dataTitle}`}
                                onClick={handleMoreOffices}
                              >
                                <div>
                                  {t("company.title-show-more")}
                                  {showMoreOffices ? (
                                    <MdKeyboardArrowUp />
                                  ) : (
                                    <MdKeyboardArrowDown />
                                  )}
                                </div>
                              </div>
                              {showMoreOffices ? (
                                <div className={styles.dataTitle}>
                                  {t("company.title-secondary-office")}
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      ) : showMoreOffices ? (
                        <div className={styles.directionContainer}>
                          <div className={styles.streetContainer}>
                            <BiMap className={styles.icon} />
                            <div>
                              <div>
                                {oficina.calle + " " + oficina.altura + ","}
                              </div>
                              <div>
                                {oficina.ciudad +
                                  ", " +
                                  oficina.provincia +
                                  ", " +
                                  oficina.pais}
                              </div>
                            </div>
                          </div>
                          <div className={styles.streetContainer}>
                            <RiPhoneLine className={styles.icon} />
                            <div>{oficina.telefono}</div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}

                <div>
                  <div className={styles.dataTitle}>
                    {t("company.section-contact")}
                  </div>
                  <div className={styles.contactContainer}>
                    <div className={styles.contactRow}>
                      <Button
                        isSecondary={true}
                        text={"Email"}
                        icon={HiOutlineMail}
                        tooltip={
                          (copied
                            ? "Email en el portapapeles: "
                            : "Copiar email: ") + company.contacto.email
                        }
                        onClick={(e) =>
                          handleButtonContact(e, company.contacto.email)
                        }
                      />
                      <Button
                        isSecondary={true}
                        text={"Web"}
                        icon={BiWorld}
                        onClick={(e) =>
                          handleButtonContact(e, company.contacto.web)
                        }
                      />
                    </div>

                    <div className={styles.contactRow}>
                      {company.contacto.facebook ? (
                        <Button
                          isSecondary={true}
                          icon={IoLogoFacebook}
                          onClick={(e) =>
                            handleButtonContact(e, company.contacto.facebook)
                          }
                        />
                      ) : null}
                      {company.contacto.instagram ? (
                        <Button
                          isSecondary={true}
                          icon={IoLogoInstagram}
                          onClick={(e) =>
                            handleButtonContact(e, company.contacto.instagram)
                          }
                        />
                      ) : null}
                      {company.contacto.linkedin ? (
                        <Button
                          isSecondary={true}
                          icon={IoLogoLinkedin}
                          onClick={(e) =>
                            handleButtonContact(e, company.contacto.linkedin)
                          }
                        />
                      ) : null}
                      {company.contacto.twitter ? (
                        <Button
                          isSecondary={true}
                          icon={IoLogoTwitter}
                          onClick={(e) =>
                            handleButtonContact(e, company.contacto.twitter)
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          {company.categorias.length > 0 ? (
            <Section title={t("company.section-categories")}>
              <Chips dataArray={company.categorias} isPrimary={true} />
            </Section>
          ) : null
          }

          {company.clientes.length > 0 ? (
            <Section title={t("company.section-clients-projects")}>
              {company.clientes.map((cliente, i) => (
                <DobleDataContainer
                  key={i}
                  clientName={cliente.nombre}
                  projectName={cliente.proyecto}
                />
              ))}
            </Section>
          ) : null}

          {company.personal.length > 0 ? (
            <Section title={t("company.section-personal")}>
              <CardEmployee dataList={company.personal} />
            </Section>
          ) : null}

          {company.partners.length > 0 ? (
            <SectionGrid title={t("company.section-partners")}>
              <CardPartners dataList={company.partners} />
            </SectionGrid>
          ) : null}

          {company.inversionistas.length > 0 ? (
            <Section title={t("company.section-investment")}>
              {company.inversionistas.map((inversionista, i) => (
                <DobleDataContainer
                  key={i}
                  nameInvestor={inversionista.nombre}
                  amount={inversionista.monto}
                />
              ))}
            </Section>
          ) : null}

          {company.productos ? (
            <SectionGrid title={t("company.section-products")}>
              <ListResultProducts list={company.productos} />
            </SectionGrid>
          ) : null}

          <StickyBottomContainer>
            {isFavorite ? (
              <Button
                fullWidth={false}
                icon={IoMdHeart}
                onClick={handleRemoveFavorite}
              />
            ) : (
              <Button
                fullWidth={false}
                icon={IoMdHeartEmpty}
                onClick={handleFavorite}
                isSecondary={true}
              />
            )}
            <Button
              onClick={(e) => handleButtonContact(e, company.contacto.web)}
              text={t("controls.button-goto-web-mobile")}
              icon={BiWorld}
              isSecondary={true}
            />
            <Button
              onClick={handleButtonBrochure}
              text={t("controls.button-brochure")}
              icon={FiFileText}
            />
          </StickyBottomContainer>
        </div>
      ) : (
        <EmptyResult />
      )}
    </div>
  );
}

export default Company;
