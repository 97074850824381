import React from "react";

function Footer() {
    return (
        <div>
            {/*Footer*/}
        </div>
    );
}

export default Footer;
