const initialState = [];

function companiesReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_ALL_COMPANIES':
            return action.payload.map(companies => {
                return {
                    ...companies,
                    selected: false
                }
            })
        case 'SELECT_CATEGORY':
            return state.map(categories => {
                if (categories.id !== action.id) {
                    return categories
                }
                categories.selected = !categories.selected
                return categories
            })
        case 'REMOVE_CATEGORY':
            return state.filter(category => category.id !== action.id)
        default:
            return state
    }
}

export default companiesReducer
