import React from "react";
import styles from "./popUp.module.scss"
import Button from "../Button/button";
import {IoCheckmarkSharp, IoClose, IoDownloadOutline} from "react-icons/io5";
import {BiError} from "react-icons/bi";
import {useTranslation} from "react-i18next";

function PopUp({text, onClose, onClickButton, progress}) {
    const {t} = useTranslation('global');

    const generateBodyPopUp = () => {
        switch (progress) {
            case -2:
                /*On error*/
                return <div className={styles.textContainer}>
                    <BiError className={styles.bodyIcon}/>
                    <span>{t("popup.text-error")}</span>
                </div>;
            case -1:
                /*Select Language*/
                return (
                    <div>
                        <div className={styles.textContainer}>{text}</div>
                        <div className={styles.buttonContainers}>
                            <Button text={t("popup.button-spanish")} onClick={() => onClickButton('ES')}/>
                            <Button text={t("popup.button-english")} onClick={() => onClickButton('EN')}/>
                        </div>
                    </div>
                );
            case 0:
                /*Before download*/
                return <div className={styles.textContainer}>
                    <IoDownloadOutline className={styles.bodyIcon}/>
                    <span>{t("popup.text-preparing")}</span>
                </div>;
            case 100:
                /*On finish*/
                return <div className={styles.textContainer}>
                    <IoCheckmarkSharp className={styles.bodyIcon}/>
                    <span>{t("popup.text-finished")}</span>
                </div>;
            default:
                /*Downloading*/
                return <div className={styles.textContainer}><IoDownloadOutline
                    className={styles.bodyIcon}/><span>{`${t("popup.text-downloading")} - ${progress}% `} </span></div>;
        }
    }
    return (
        <div className={styles.popup}>
            <div className={styles.popupInner} onClick={onClose}>
            </div>

            <div className={styles.popupBody}>
                <div className={styles.closeContainer}><IoClose onClick={onClose} className={styles.icon}/>
                </div>
                {generateBodyPopUp()}
            </div>
        </div>
    );
}

export default PopUp;
