import React from "react";
import styles from "./video.module.scss"

function Video({urlVideo}) {

    function YouTubeGetID(url) {
        let ID;
        url = url.replace(/([><])/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_-]/i);
            ID = ID[0];
        } else {
            ID = url;
        }
        return `https://www.youtube.com/embed/${ID}`;
    }


    return (
        <div className={styles.videoContainer}>
            <iframe
                title={'videoCompanyFrame'}
                className={styles.videoFrame}
                src={YouTubeGetID(urlVideo)}
                frameBorder="0"
            />
        </div>
    );
}

export default Video;
