import {combineReducers, createStore} from 'redux';
import searchReducer from "./reducers/search.reducer";
import companyReducer from "./reducers/company.reducer";
import categoriesReducer from "./reducers/categories.reducer";
import productReducer from "./reducers/product.reducer";
import favoriteReducer from "./reducers/favorites.reducer";
import searchTermReducer from "./reducers/searchTerm.reducer";
import tokenReducer from "./reducers/token.reducer";
import companiesReducer from './reducers/companies.reducer'; 

const reducer = combineReducers({
    searchReducer,
    companiesReducer,
    companyReducer,
    categoriesReducer,
    productReducer,
    favoriteReducer,
    searchTermReducer,
    tokenReducer,
});

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
