import React, { useEffect } from "react";
import './styles/global.scss';
import Layout from "./components/Layout/layout";
import Search from "./pages/Search/search";
import Company from "./pages/Company/company";
import Product from "./pages/Product/product";
import Favorites from "./pages/Favorites/favorites";
import { I18nextProvider } from 'react-i18next';
import {Route, Switch,} from "react-router-dom";
import ListCompanies from "./components/ListCompanies/listCompanies";
import { pageView } from "./services/analytic.service";
import i18nextConfig from "./translations/translation"

function App() {
    useEffect(() => {
        // Código de configuración de GA4
        pageView()
      }, []);

    // Determinar el idioma según el dominio actual
    const currentLanguage = window.location.hostname === process.env.REACT_APP_DOMAIN_EN ? 'en' : 'es';

    // Cargar las traducciones correspondientes
    i18nextConfig.changeLanguage(currentLanguage);
    return (
        <I18nextProvider i18n={i18nextConfig}>
            <Layout>
            <Switch>
                <Route path="/" component={Search} exact/>
                <Route path="/search" component={Search}/>
                <Route path="/company/:id" component={Company}/>
                <Route path="/product/:id" component={Product}/>
                <Route path="/favorites" component={Favorites}/>
                <Route path="/nuestros-socios" component={ListCompanies}/>
                <Route path="*" component={Search}/>
            </Switch>
        </Layout>
        </I18nextProvider>
    );
}

export default App;
