import React from "react";
import styles from "./addToFavoriteButton.module.scss";
import {IoMdHeart, IoMdHeartEmpty} from "react-icons/io";
import {useTranslation} from "react-i18next";

function AddToFavoriteButton({isFavorite, handleRemoveFavorite, handleFavorite}) {
    const {t} = useTranslation('global');

    return (
        isFavorite
            ? (<div
                className={`${styles.favoriteLabel} ${styles.favoriteLabelAdded}`}
                onClick={handleRemoveFavorite}
            >{t("addToFavoriteButton.remove-favorite-text")} <IoMdHeart className={styles.iconFav}/>
            </div>)
            : (
                <div
                    className={styles.favoriteLabel}
                    onClick={handleFavorite}
                >{t("addToFavoriteButton.add-favorite-text")} <IoMdHeartEmpty className={styles.iconFav}/>
                </div>
            )
    );
}

export default AddToFavoriteButton;
