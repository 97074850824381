import axios from 'axios'
import {apiUrl} from "./login.service";


async function downloadOnePager(idProduct, templeName, language, config = {}) {
    try {
        return await axios.get(`${apiUrl}empresas/productos/${idProduct}/onepager/${templeName}/${language}/pdf`,
            config
            ,)
    } catch (e) {
        console.log("Error:", e)
    }
}


export {
    downloadOnePager,
};
