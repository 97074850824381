const initialState = {
    userSearch: '',
    categorySearch: [],
    stringCompleteSearch: ''
};


function searchTermReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_TERM_SEARCH':
            let stringCompleteSearch1 = `${action.term} `

            state.categorySearch.forEach(category => {
                stringCompleteSearch1 += `${category.id} `
            })
            return {
                ...state,
                userSearch: action.term,
                stringCompleteSearch: stringCompleteSearch1
            }
        case 'SET_TERM_SEARCH_CATEGORIES':
            let stringCompleteSearch2 = `${state.userSearch} `
            let indexCategory = state.categorySearch.findIndex(category => category.titulo === action.selectedCategories.titulo);

            let newCategoryArray = [...state.categorySearch]

            if (indexCategory === -1) { //no lo encontro
                newCategoryArray.push(action.selectedCategories)
            } else {
                newCategoryArray = state.categorySearch.filter((category) => category.titulo !== action.selectedCategories.titulo)
            }

            let stringResult = ''

            /*if (newCategoryArray.length > 0) {
                newCategoryArray.forEach(category => {
                    if( stringResult ){
                        stringResult += ' ';
                    }
                    stringResult += `${category.id}`
                })

                for( let i=0; i<newCategoryArray.length; i++ ){
                    if( i !== 0 ) {
                        stringResult += ' '
                    }  
                    stringResult += `${newCategoryArray[i].id}`
                }
            }*/

            for( let i=0; i<newCategoryArray.length; i++ ){
                if( i !== 0 ) {
                    stringResult += ' '
                }  
                stringResult += `${newCategoryArray[i].id}`
            }

            return {
                ...state,
                userSearch: state.userSearch,
                categorySearch: [...newCategoryArray],
                stringCompleteSearch: stringCompleteSearch2 += `${stringResult}`
            }
        case 'RESET_TERM_SEARCH':
            return initialState
        default:
            return state
    }
}

export default searchTermReducer
