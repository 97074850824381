import React from "react";
import styles from "./section.module.scss"
import SectionTitle from "../SectionTitle/sectionTitle";

function Section({children, title, grey = false}) {
    return (
        <div className={`${styles.sectionContainer} ${grey ? styles.grey : null}`}>
            <SectionTitle text={title}/>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
        ;
}

export default Section;
