const initialState = {
    productos: [],
    empresas: []
};

function productReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_FAVORITE_PRODUCT':
            let indexProduct = state.productos.findIndex(item => item.id === action.payload.id);
            if (indexProduct === -1) {
                return {
                    ...state,
                    productos: [
                        ...state.productos,
                        {
                            ...action.payload,
                            // isFavorite: true
                        }
                    ]

                }
            }
            return state
        case 'REMOVE_FAVORITE_PRODUCT':
            return {
                ...state,
                productos: state.productos.filter((item) => item.id !== action.payload)
            }
        case 'ADD_FAVORITE_COMPANY':
            let indexCompany = state.empresas.findIndex(item => item.id === action.payload.id);
            if (indexCompany === -1) {
                return {
                    ...state,
                    empresas: [
                        ...state.empresas,
                        {
                            ...action.payload,
                            // isFavorite: true
                        }
                    ]
                }
            }
            return state
        case 'REMOVE_FAVORITE_COMPANY':
            return {
                ...state,
                empresas: state.empresas.filter((item) => item.id !== action.payload)
            }
        default:
            return state
    }
}

export default productReducer
