import React, {useState, useEffect} from "react";
import styles from './search.module.scss'
import {useSelector} from "react-redux";
import ListCategories from "../../components/ListCategories/listCategories";
import {useTranslation} from "react-i18next";
import SearchBar from "../../components/SearchBar/searchBar";
import FilteredCategories from "../../components/FilteredCategories/filteredCategories";
import CardProduct from "../../components/Cards/CardProduct/cardProduct";
import CardCompany from "../../components/Cards/CardCompany/cardCompany";
import ResultLabel from "../../components/ResultLabel/resultLabel";
import Button from "../../components/Button/button";
import Alert from "../../components/Alert/alert";
import { CSVLink } from "react-csv";

const ITEMS_PER_VIEW = 4
const headers = [
    { label: "Id", key: "id" },
    { label: "Nombre", key: "nombre" },
    { label: "Tipo", key: "tipo" },
    { label: "Tags", key: "tags" },
    { label: "Fecha Creacion", key: "createdAt" },
    { label: "Fecha Ult Act", key: "updatedAt" },
    { label: "Empresa", key: "empresa.nombre" },
    { label: "Web", key: "web" },
    { label: "Empresa Cuit", key: "empresa.cuit" },
    { label: "Empresa Razon Social", key: "empresa.razonSocial" },
];

function Search() {
    const {t} = useTranslation('global');

    const [itemsPerViewProduct, setItemsPerViewProduct] = useState(ITEMS_PER_VIEW)
    const [/*itemsPerViewCompany*/, setItemsPerViewCompany] = useState(ITEMS_PER_VIEW)

    const {productsList, companiesList, showEmpty} = useSelector(state => {
        return {
            productsList: state.searchReducer.productos,
            companiesList: state.searchReducer.empresas,
            showEmpty: state.searchReducer.showEmpty
        }
    });

    const handleMoreResultsProduct = () => {
        setItemsPerViewProduct(prev => prev + ITEMS_PER_VIEW)
    }

    /*const handleMoreResultsCompany = () => {
        setItemsPerViewCompany(prev => prev + ITEMS_PER_VIEW)
    }*/

    useEffect(() => {
        setItemsPerViewCompany(ITEMS_PER_VIEW)
        setItemsPerViewProduct(ITEMS_PER_VIEW)
    }, [])

    return (
        <div className={styles.searchContainer}>
            <div className={styles.hideOnDesktop}>
                <SearchBar/>
            </div>
            {
                showEmpty ? <Alert text={t("search.empty-result")}/> : null
            }
            {
                (productsList.length > 0 || companiesList.length > 0) ? (
                    <div className={styles.resultContainer}>
                        <FilteredCategories/>
                        <div className={styles.resultSection}>
                            {productsList.length > 0 && (
                                <>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>{t("search.product-title")}</div>
                                        <ResultLabel length={productsList.length}/>
                                        <div className={styles.buttonExcel}>
                                        <CSVLink 
                                            data={productsList} 
                                            headers={headers}
                                            filename="CTC"
                                            className={styles.title}
                                            target="_blank"
                                            >
                                            Descargar
                                        </CSVLink>
                                    </div>
                                    </div>
                                    <div className={styles.flexGrid}>
                                        {productsList.slice(0, itemsPerViewProduct).map((product, index) => (
                                            <CardProduct key={index} product={product}/>
                                        ))}
                                    </div>
                                </>
                            )}
                            {productsList.length > ITEMS_PER_VIEW && itemsPerViewProduct <= productsList.length
                                ? (
                                    <div className={styles.showMoreContainer}>
                                        <Button text={t('search.text-show-more')} fullWidth={false} isSecondary={true}
                                                onClick={handleMoreResultsProduct}/>
                                    </div>
                                )
                                : null}
                        </div>
                        <div className={styles.resultSection}>
                            {companiesList.length > 0 && (
                                <div className={styles.related}>
                                    <div className={`${styles.resultSection}`}>
                                        <>
                                            <div className={styles.titleContainer}>
                                                <div className={styles.title}>{t("search.company-title")} </div>
                                                <ResultLabel length={companiesList.length}/>
                                            </div>
                                            <div className={styles.flexGrid}>
                                                {companiesList.map((company, index) => (
                                                    <CardCompany key={index} company={company}/>
                                                ))}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            )}
                            {/*companiesList.length > ITEMS_PER_VIEW && itemsPerViewCompany <= companiesList.length
                                ? (
                                    <div className={styles.showMoreContainer}>
                                        <Button text={t('search.text-show-more')} fullWidth={false} isSecondary={true}
                                                onClick={handleMoreResultsCompany}/>
                                    </div>
                                )
                                : null*/}
                        </div>
                    </div>
                ) : (<ListCategories/>)
            }
        </div>
    );
}

export default Search;
