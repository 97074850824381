import React from "react";
import styles from "./controls.module.scss";
import {useTranslation} from "react-i18next";
import Button from "../Button/button";
import {BiWorld} from "react-icons/bi";
import {FiFileText} from "react-icons/fi";
import {IoMdHeart, IoMdHeartEmpty} from "react-icons/io";

function Controls({
                      isFavorite,
                      handleFavorite,
                      handleButtonWeb,
                      handleButtonOnePager,
                      handleButtonBrochure,
                      handleRemoveFavorite
                  }) {

    const {t} = useTranslation('global');

    return (
        <div className={styles.controls}>
            {isFavorite
                ? (<Button fullWidth={false} isSecondary={true} text={t("controls.button-favorite")} icon={IoMdHeart}
                           onClick={handleRemoveFavorite}/>)
                : (<Button fullWidth={false} isSecondary={true} text={t("controls.button-add-favorite")}
                           icon={IoMdHeartEmpty}
                           onClick={handleFavorite}/>)
            }

            <Button fullWidth={false} isSecondary={true} text={t("controls.button-goto-web")} icon={BiWorld}
                    onClick={handleButtonWeb}/>

            {handleButtonOnePager
                ? (
                    <Button fullWidth={false} text={t("controls.button-one-pager")} icon={FiFileText}
                            onClick={handleButtonOnePager}/>
                ) : null}
            {handleButtonBrochure
                ? (
                    <Button fullWidth={false} text={t("controls.button-brochure")} icon={FiFileText}
                            onClick={handleButtonBrochure}/>
                ) : null}
        </div>
    );
}

export default Controls;
