import axios from 'axios'
import {apiUrl, searchApiUrl} from "./login.service";
import qs from 'qs';
import { apiUrlBase } from './api';

const URL_SEARCH = 'company?code=ES&phrase='
const URL_COMPANIES = 'empresas?id='
const URL_COMPANIES_FILTER = 'empresas?'
const URL_PRODUCTS = 'empresas/productos/'
const URL_CATEGORIES = 'categorias?_sort=titulo:ASC'
//const URL_FILTER_SOLUCIONS = 'solucions?'
const URL_FILTER_SOLUCIONS = 'solucions/filtrar/'
const URL_FILTER_SOL = 'solucions?'
const URL_FILTER_COMP = 'empresas?'
const URL_FIND_ID= 'empresas/findIdByUrl/'
const URL_FILTER_BY_COMPANY = 'solucions/findByCompany/'
const URL_FILTER_SOL_EMP = 'solucions?empresa='
const URL_COMPANIES_ALL = 'empresas/all'

async function getServicesByTerm(termSearch) {
    try {
        let result = {
            data: null
        }
        if (termSearch !== '') {
            result = await axios.get(searchApiUrl + URL_SEARCH + termSearch)
        }
        return result.data;
    } catch (e) {
        console.log("Error:", e)
    }
}

async function getDataCompanyById(item) {
    try {

        if (!item) {
            return {};
        }
        const isObjectId = await isMongoDBId(item);
        let result;
        if (!!isObjectId) { //Por id
            result = await apiUrlBase.get(`${URL_COMPANIES + item}`)
            return result.data.length > 0 ? result.data[0] : {};
        } else { //Por nombre
            let categorias = [];
            const res = await apiUrlBase.get(`${URL_FIND_ID + item}`); //equal to {empresa, solucions}
            const empresa = res.data.empresa;
            const solucions = res.data.solucions;
            solucions.forEach(item => {
                categorias = categorias.concat(item.categorias) 
                
            })
            categorias = categorias.filter((item, pos, self) => self.findIndex(v => v.titulo === item.titulo) === pos);   
            empresa.categorias = categorias;
            empresa.productos = solucions;
            return empresa || {};
        }
    } catch (e) {
        console.log("Error:", e)
    }
}

async function getAllCategories() {
    try {
        console.log("getAllCategories")
        const result = await apiUrlBase.get(URL_CATEGORIES)

        return result.data;
    } catch (e) {
        console.log("Error:", e)
    }
}

async function getAllCompanies() {
    try {
        const result = await apiUrlBase.get(URL_COMPANIES_ALL)
        return result.data;
    } catch (e) {
        console.log("Error:", e)
    }
}

async function getDataProductById(productId) {
    try {
        const result = await apiUrlBase.get(URL_PRODUCTS + productId)
        return result.data;
    } catch (e) {
        console.log("Error:", e)
    }
}

async function getFilterSolucionByCategoryId(categoryIds) { //categoryIds
    try {
        let resultSolucion = {
            data: null
        }
        if (categoryIds !== '') {
            resultSolucion = await apiUrlBase.get(URL_FILTER_SOLUCIONS + `${categoryIds}`);        
        }
        return { productos: resultSolucion.data.solucion, empresas: [] };
    } catch (e) {
        console.log("Error:", e)
    }
}

async function findSolucionsByCategoryName(word) {
    try {
        const query = qs.stringify({
            _where: {
                _or: [
                    [{ nombre_contains: `${word}`}],
                    [{ descripcion_ES_contains: `${word}`}],
                    [{ descripcion_EN_contains: `${word}`}],
                ],
            },
            });

        const result = await apiUrlBase.get(`${URL_FILTER_SOL + query}`);
        return { productos: result.data, empresas: []};
    } catch (e) {
        console.log("Error", e);
    }

}

async function isMongoDBId(val){
    let checkForValidMongoDbID = new RegExp("^[0-9a-fA-F]{24}$");
    return checkForValidMongoDbID.test(val);
}

async function getFilter(termSearch) { //categoryIds
    try {
        let resultSolucion = {
            data: null
        }

        let categoryIds = [];
        let solucionName = '';
        let empresas = [];
        let uniqueIds = [];

        if (termSearch !== '') {
            termSearch = termSearch.split(" ");

            for (let i = 0; i < termSearch.length; i++) {
                const isObjectId = await isMongoDBId(termSearch[i]);
                if (isObjectId) { //is objectId
                    categoryIds.push(termSearch[i]);
                } else {
                    if (!!termSearch[i]) {
                        if (i === 0) {
                            solucionName += `${termSearch[i]}`
                        } else {
                            solucionName += ` ${termSearch[i]}`
                        }
                    }
                }
            }

            if (!!solucionName) {
                const query = qs.stringify({
                    _where: {
                        _or: [
                            [{ nombre_contains: `${solucionName}`}],
                            [{ descripcion_ES_contains: `${solucionName}`}],
                            [{ descripcion_EN_contains: `${solucionName}`}],
                        ],
                    },
                    });
        
                //Busca soluciones asociadas
                let res = await apiUrlBase.get(`${URL_FILTER_SOL + query}`)
                    .then(res => {
                        return res.data;
                    })

                //Busca la info de la compania 
                let resFindCompany = await apiUrlBase.get(`${URL_FILTER_BY_COMPANY + solucionName}`)
                    .then(res => {
                        return res.data;
                    });

                let companies = await apiUrlBase.get(`${URL_FILTER_COMP + query}`)
                .then(res => {
                    return res.data;
                });

                companies.forEach(item => {
                    if (!uniqueIds.includes(item.id)) {
                        uniqueIds.push(item.id);
                        empresas.push(item);
                    }
                });

                res = res.concat(resFindCompany);

                if (categoryIds.length > 0) {
                    res = res.filter(item => {
                        return item.categorias.some(category => categoryIds.includes(category.id));
                    });
                }

                res.forEach(item => {
                    if (item.empresa) {
                        if (!uniqueIds.includes(item.empresa.id)) {
                            uniqueIds.push(item.empresa.id);
                            empresas.push(item.empresa);
                        }
                    }
                });

                return { productos: res, empresas };
            } else {
                resultSolucion = await apiUrlBase.get(URL_FILTER_SOLUCIONS + `${categoryIds}`);
                return { productos: resultSolucion.data.solucion, empresas: resultSolucion.data.empresas };
            }
        }
    } catch (e) {
        console.log("Error:", e)
    }
}

async function findCategoriesByCompanyId(id) {
    try {
        let categorias = [];
        //Devuelve soluciones asociadas a una empresa
        const res = await apiUrlBase.get(URL_FILTER_SOL_EMP + id);
        const solucions = res.data;
        solucions.forEach(item => {
            categorias = categorias.concat(item.categorias) 
            
        })
        categorias = categorias.filter((item, pos, self) => self.findIndex(v => v.titulo === item.titulo) === pos);
        return categorias;
    } catch (e) {
        console.log("Error:", e)
    }
}


export {
    getServicesByTerm,
    getDataCompanyById,
    getAllCategories,
    getDataProductById,
    getFilterSolucionByCategoryId,
    findSolucionsByCategoryName,
    getFilter,
    getAllCompanies,
    findCategoriesByCompanyId
};
