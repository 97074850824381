import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux";
import store from "./redux/store";
import {I18nextProvider} from "react-i18next";
import i18nextConfig from "./translations/translation"
import packageJson from '../package.json';

console.log(packageJson.version);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
