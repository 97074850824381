import React from "react";
import Chip from "./Chip/chip";
import {addResults, selectCategory, setTermSelectedCategory} from "../../redux/actions";
import {useDispatch, useStore} from "react-redux";
import {getFilter} from "../../services/search.service";

function Chips({
                   dataArray,
                   isPrimary = false,
                   isCloseable = false,
                   showSelected = false,
                   isSelectable = false
               }) {
    const dispatch = useDispatch();


    const store = useStore()

    const handleSelectedCategory = (category) => {
        if (isSelectable) {
            dispatch(selectCategory(category.id))
            dispatch(setTermSelectedCategory(category))
        }
    }

    const handleOnCloseCategory = (category) => {
        dispatch(setTermSelectedCategory(category))
        dispatch(selectCategory(category.id))

        /*getServicesByTerm(store.getState().searchTermReducer.stringCompleteSearch).then(
            res => {
                dispatch(addResults(res));
            }
        );*/

        getFilter(store.getState().searchTermReducer.stringCompleteSearch).then(
            res => {
                dispatch(addResults(res));
            }
        );
    }

    const checkIfShowSelected = () => {
        dataArray = showSelected ? dataArray.filter(data => data.selected) : dataArray
    }
    checkIfShowSelected();

    return (
        <>
            {dataArray.map((data, index) => (
                <Chip
                    key={index} data={data.titulo ? data.titulo : data}
                    onClick={() => handleSelectedCategory(data)}
                    isSelected={data.selected}
                    isPrimary={isPrimary}
                    onClose={isCloseable ? () => handleOnCloseCategory(data) : false}
                />
            ))}
        </>
    );
}

export default Chips;
