import React from "react";
import styles from './listResultProducts.module.scss'
import CardProduct from "../Cards/CardProduct/cardProduct";

function ListResultProducts({list}) {
    return (
        <div className={styles.listContainer}>
            {
                list.map((product, index) => (
                    <CardProduct key={index} product={product}/>
                ))
            }
        </div>
    );
}

export default ListResultProducts;
