import React from "react";
import styles from "./header.module.scss"
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IoSearchOutline} from "react-icons/io5";
import {GoOrganization} from "react-icons/go";
import {IoMdHeart} from "react-icons/io";
import {useSelector} from "react-redux";
import SearchBar from "../SearchBar/searchBar";

function Header() {

    const {i18n} = useTranslation('global');
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation('global');

    const handleButtonFavorites = () => {
        history.push('/favorites');
    }

    const handleButtonSearch = () => {
        history.push('/search');
    }

    const handleButtonCompanies = () => {
        history.push('/nuestros-socios');
    }

    const {productsFavorites, companiesFavorites} = useSelector(state => {
        return {
            productsFavorites: state.favoriteReducer.productos,
            companiesFavorites: state.favoriteReducer.empresas,
        }
    });

    return (
        <div>
            <div className={styles.header}>
                <NavLink to='/' className={styles.logoContainer}>
                    <div>
                        <img src={'../assets/images/LogoCC.png'}
                             alt="Cordoba Technology Cluster Logo"/>
                    </div>
                </NavLink>

                <div className={styles.hideOnMobile}>
                    <SearchBar/>
                </div>
                <div className={styles.menuContainer}>

                    <div
                        className={`${styles.menuButton} ${location.pathname === '/search' || location.pathname === '/' ? styles.isSelected : null}`}
                        onClick={handleButtonSearch}>
                        <IoSearchOutline className={styles.icon}/>
                    </div>
                    <div
                        className={`${styles.menuButton} ${location.pathname === '/favorites' ? styles.isSelected : null}`}
                        onClick={handleButtonFavorites}>
                        {(productsFavorites.length + companiesFavorites.length)}
                        <IoMdHeart className={styles.icon} style={{paddingLeft: '0.3em'}}/>
                    </div>
                    <div
                        className={`${styles.menuButton} ${location.pathname === '/nuestros-socios' ? styles.isSelected : null}`}
                        onClick={handleButtonCompanies}>
                        {t("company.title-company")}
                        <GoOrganization className={styles.icon} style={{paddingLeft: '0.3em'}}/>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Header;
