import axios from 'axios'
import {isExpired} from "react-jwt";

export const searchApiUrl = process.env.REACT_APP_URL_SEARCH;
export const apiUrl = process.env.REACT_APP_URL_API;


axios.interceptors.request.use(
    async config => {
        let token;
        const actualToken = localStorage.getItem('token');
        if(actualToken){
            const isMyTokenExpired = isExpired(actualToken);
            token = isMyTokenExpired ? await getToken() : actualToken;
        }else {
            token = await getToken();
        }
        config.headers.authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(null, async (error) => {
    if (
        error.config &&
        error.response?.status === 403 && // Use the status code your server returns when token has expired
        !error.config.__isRetry
    ) {
        const token = await getToken();
        axios.defaults.headers.authorization = `Bearer ${token}`;
        return lastRequest(error.config)
    }
    return Promise.reject(error);
});

const lastRequest = (config) => {
    return new Promise((resolve, reject) => {
        axios
            .request(config) // Repeat the initial request
            .then((result) => {
                return resolve(result);
            })
            .catch((e) => {
                console.log("Error:", e)
                return reject(e);
            });

    });
};

async function getToken() {
    const data = {
        identifier: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PASSWORD
    }
    try {
        const res = await fetch(apiUrl + 'auth/local', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        const dataJson = await res.json();
        localStorage.setItem('token', dataJson.jwt)
        return dataJson.jwt
    } catch (e) {
        console.log("Error:", e)
    }
}
