import React, {useEffect, useState} from "react";
import {getAllCategories, getFilter} from "../../services/search.service";
import {useDispatch, useSelector} from "react-redux";
import {addAllCategories, addResults,} from "../../redux/actions";
import styles from './listCategories.module.scss'
import {useTranslation} from "react-i18next";
import StickyBottomContainer from "../StickyBottonContainer/stickyBottomContainer";
import Chips from "../Chips/chips";
import { clickButton } from "../../services/analytic.service";

function ListCategories() {
    const dispatch = useDispatch();
    const {t} = useTranslation('global');
    const [disableButtonSearch, setDisableButtonSearch] = useState(false);

    const {categories, termSearch} = useSelector(state => {
        return {
            categories: state.categoriesReducer,
            termSearch: state.searchTermReducer.stringCompleteSearch
        }
    });

    useEffect(() => {
        setDisableButtonSearch(false);
        if (Object.keys(categories).length === 0) {
            getAllCategories().then(res => dispatch(addAllCategories(res)))
        }

        categories.forEach(categories => {
            if (categories.selected) {
                setDisableButtonSearch(true)
            }
        })
    }, [categories, dispatch]);

    const handleButtonSelection = () => {
        if (disableButtonSearch) {
            getFilter(termSearch).then(
                res => {
                    dispatch(addResults(res));
                }
            );
        }
        clickButton('Categorias', termSearch)
    }

    /*const handleButtonSelection = () => {
        if (disableButtonSearch) {
            getServicesByTerm(termSearch).then(
                res => {
                    dispatch(addResults(res));
                }
            );
        }
    }*/

    return (
        <div className={styles.categoriesContainer}>
            <div>
                <div className={styles.categoriesSection}>
                    <div className={styles.titleSection}>
                        <h2>{t("list-categories.title")}</h2>
                        <div
                            className={`${styles.hiddenMobile}`}>{t("list-categories.help-label")}</div>
                    </div>
                    <div className={styles.categoriesChipSection}>
                        <Chips dataArray={categories} isSelectable={true}/>

                        <div onClick={handleButtonSelection}
                             className={`${styles.stickyButton} ${styles.hiddenMobile}  ${disableButtonSearch ? styles.enabled : ''}`}>
                            {t("search.button-category-search")}
                        </div>
                    </div>
                </div>
                <StickyBottomContainer>
                    <div onClick={handleButtonSelection}
                         className={`${styles.stickyButton}  ${styles.hiddenDesktop} ${styles.hiddenTablet} ${disableButtonSearch ? styles.enabled : ''}`}>
                        {t("search.button-category-search")}
                    </div>
                </StickyBottomContainer>
            </div>
        </div>
    );
}

export default ListCategories;
